import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestTime } from '../Redux/Actions/api';
import { menuCollapse, setAccessedMenu, setAccessedSubMenu } from '../Redux/Actions/nav';
import { sessionManager } from '../Redux/Actions/session';
import { ActionTypes } from '../Redux/Constants';

// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const NavigationScroll = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.authentication);
    const userRole = useSelector(state => state.masters.userRole);
    const { pathname } = location;
    const exemptUrl = ['/internalServerError', '/pageNotFound', '/forgetPassword']
    const validateUrl = (url) => {
        if (exemptUrl.includes(url)){
            return false;
        }
        if ((url === '/' || url === '') && auth.isAuthenticated) {
            console.log("goinghere",url, auth)
            let mitem = userRole.sideBar.filter(item => item.title === 'Dashboard')
            if (mitem) {
                mitem = mitem[0]
                dispatch(menuCollapse(mitem.id))
                dispatch(setAccessedMenu(mitem))
                dispatch(setAccessedSubMenu(mitem.submenu[0]))
                return true
            }

        }
        let data = userRole && userRole.sideBar.filter(item => {
            if (item.url.match(url)) {
                dispatch(menuCollapse(false))
                dispatch(setAccessedMenu(item))
                dispatch(setAccessedSubMenu(null))
                return item
            }
        });
        let submenu = userRole && userRole.sideBar.filter(item => {
            if (item.submenu.length > 0) {
                let submenuData = item.submenu.filter(sitem => { return sitem.url.match(url) })
                if (submenuData.length > 0) {
                    dispatch(menuCollapse(item.id))
                    dispatch(setAccessedMenu(item))
                    dispatch(setAccessedSubMenu(submenuData[0]))
                    return item
                }
            }
        });
        let setingMenuData = userRole && userRole.settingsMenu.filter(item => {
            if (item.url.match(url)) {
                dispatch(menuCollapse(false))
                dispatch(setAccessedMenu(item))
                dispatch(setAccessedSubMenu(null))
                return item
            }
        })
        if (data && data.length === 0) {
            if (submenu && submenu.length === 0) {
                if (setingMenuData && setingMenuData.length === 0){
                    navigate('/pageNotFound')
                }
            }
        }
    }
    useEffect(() => {
        dispatch(requestTime(null));
        //dispatch({type: ActionTypes.ORDER_MANIFEST, payload: null})
        sessionManager({ state: auth, dispatch, navigate, location })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        validateUrl(pathname)
    }, [pathname]);

    return children || null;
};

NavigationScroll.propTypes = {
    children: PropTypes.node
};

export default NavigationScroll;
