import { lazy } from "react";
import Loadable from "../Navigations/loadable";
import MainLayout from "../Layout";

const Login = Loadable(lazy(() => import('../UIComponents/Authentications/login')));

const AnalyticDashboard = Loadable(lazy(() => import('../UIComponents/Home/analyticDashboard')));
const Dashboard = Loadable(lazy(() => import('../UIComponents/Home/dashboard')));
const Tracking = Loadable(lazy(() => import('../UIComponents/Tracking/tracking')));
const Account = Loadable(lazy(() => import('../UIComponents/Account/account')));
const Error404 = Loadable(lazy(() => import('../UIComponents/Default/404')));
const Error500 = Loadable(lazy(() => import('../UIComponents/Default/500')));
const OrderList = Loadable(lazy(() => import('../UIComponents/Order/orderList')));
const AddOrder = Loadable(lazy(() => import('../UIComponents/Order/addOrder')));
const UploadOrder = Loadable(lazy(() => import('../UIComponents/Order/uploadOrder')));
//Shipment
const UploadShipment = Loadable(lazy(() => import('../UIComponents/Shipment/uploadShipment')));
const AddShipment = Loadable(lazy(() => import('../UIComponents/Shipment/addShipment')));
const ShipmentList = Loadable(lazy(() => import('../UIComponents/Shipment/shipmentList')));
const ShippingLabel = Loadable(lazy(() => import('../UIComponents/Shipment/shippingLabel')));
const ShipmentCancel = Loadable(lazy(() => import('../UIComponents/Shipment/shipmentCancel')));

//B2BInternalShipment
const B2BInternalShippingLabel = Loadable(lazy(() => import('../UIComponents/B2BInternal/shippingLabel')));
const B2BInternalFetchAWB = Loadable(lazy(() => import('../UIComponents/B2BInternal/fetchAWB')));
const AddB2BInternalShipment = Loadable(lazy(() => import('../UIComponents/B2BInternal/addShipment')));
const UploadB2BInternalShipment = Loadable(lazy(() => import('../UIComponents/B2BInternal/uploadB2BShipment')));
const B2BInternalShipmentList = Loadable(lazy(() => import('../UIComponents/B2BInternal/shipmentList')));
const B2BInternalUpdateShipment = Loadable(lazy(() => import('../UIComponents/B2BInternal/updateShipment')));
const B2BInternalCancel = Loadable(lazy(() => import('../UIComponents/B2BInternal/shipmentCancel')));

//B2BShipment
const B2BUploadShipment = Loadable(lazy(() => import('../UIComponents/B2BShipment/uploadShipment')));
const B2BAddShipment = Loadable(lazy(() => import('../UIComponents/B2BShipment/addShipment')));
const B2BShipmentList = Loadable(lazy(() => import('../UIComponents/B2BShipment/shipmentList')));
const B2BShippingLabel = Loadable(lazy(() => import('../UIComponents/B2BShipment/shippingLabel')));
const B2BShipmentCancel = Loadable(lazy(() => import('../UIComponents/B2BShipment/shipmentCancel')));

const AddCustomer = Loadable(lazy(() => import('../UIComponents/Customer/addCustomer')));
const CustomerList = Loadable(lazy(() => import('../UIComponents/Customer/customerList')));
const CustomerKYCDocument = Loadable(lazy(() => import('../UIComponents/Customer/kycDocument')));
const Remittance = Loadable(lazy(() => import('../UIComponents/Billing/remittance')));
const ShipmentReports = Loadable(lazy(() => import('../UIComponents/Reports/shipmentReports')));
const Pincode = Loadable(lazy(() => import('../UIComponents/Serviceability/pincode')));
const RateCard = Loadable(lazy(() => import('../UIComponents/Billing/ratecard')));
const RateCalculator = Loadable(lazy(() => import('../UIComponents/Billing/rateCalculator')));
const SubUser = Loadable(lazy(() => import('../UIComponents/Account/subUser')));
const PickupAddress = Loadable(lazy(() => import('../UIComponents/Account/pickupAddress')));
const ReturnAddress = Loadable(lazy(() => import('../UIComponents/Account/returnAddress')));
const CPanelConfig = Loadable(lazy(() => import('../UIComponents/Account/panelConfig')));
const WebHook = Loadable(lazy(() => import('../UIComponents/Account/webHook')));
const Couriers = Loadable(lazy(() => import('../UIComponents/Account/logisticPriority')));

export const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />,
            children:[
                {
                    path: '/dashboard',
                    element: <Dashboard />,
                }
            ]
        },
        {
            path: '/dashboard/analytic/',
            element: <AnalyticDashboard />,
            children:[
                {
                    path: '/dashboard/analytic/',
                    element: <AnalyticDashboard />,
                }
            ]
        },
        
        {
            path: 'tracking',
            children:[
                {
                    path: '/tracking',
                    element: <Tracking />,
                }
            ]
        },
        {
            path: 'order',
            children: [
                {
                    path: '/order/add/',
                    element: <AddOrder />
                },
                {
                    path: '/order/list/',
                    element: <OrderList />,
                },
                {
                    path: '/order/upload/',
                    element: <UploadOrder />
                }
            ]
        },
        {
            path: 'shipment',
            children: [
                {
                    path: '/shipment/upload/',
                    element: <UploadShipment />
                },
                {
                    path: '/shipment/add/',
                    element: <AddShipment />
                },
                {
                    path: '/shipment/list/',
                    element: <ShipmentList />
                },
                {
                    path: '/shipment/label/',
                    element: <ShippingLabel />
                },
                {
                    path: '/shipment/cancel/',
                    element: <ShipmentCancel />
                },
                // {
                //     path: '/shipment/b2bmanifest/',
                //     element: <UploadB2BShipment />
                // },
                // {
                //     path: '/shipment/update/',
                //     element: <UpdateShipment />
                // }
            ]
        },
        {
            path: 'b2b',
            children: [                
                {
                    path: '/b2b/add/',
                    element: <B2BAddShipment />
                },
                {
                    path: '/b2b/upload/',
                    element: <B2BUploadShipment />
                },
                {
                    path: '/b2b/label/',
                    element: <B2BShippingLabel />
                },
                {
                    path: '/b2b/list/',
                    element: <B2BShipmentList />
                },
                {
                    path: '/b2b/cancel/',
                    element: <B2BShipmentCancel />
                }
            ]
        },
        {
            path: 'internal',
            children: [                
                {
                    path: '/internal/fetchAWB/',
                    element: <B2BInternalFetchAWB />
                },
                {
                    path: '/internal/add/',
                    element: <AddB2BInternalShipment />
                },
                {
                    path: '/internal/bulkUpload/',
                    element: <UploadB2BInternalShipment />
                },
                {
                    path: '/internal/label/',
                    element: <B2BInternalShippingLabel />
                },
                {
                    path: '/internal/list/',
                    element: <B2BInternalShipmentList />
                },
                {
                    path: '/internal/update/',
                    element: <B2BInternalUpdateShipment />
                },
                {
                    path: '/internal/cancel/',
                    element: <B2BInternalCancel />
                }
            ]
        },
        {
            path:'reports',
            children:[
                
                {
                    path:'/reports/all/',
                    element: <ShipmentReports />
                }
            ]
        },
        {
            path:'serviceability',
            children:[
                {
                    path:'/serviceability/pincode/',
                    element:<Pincode />
                },
                
            ]
        },
        {
            path: 'customer',
            children: [
                {
                    path: '/customer/add/',
                    element: <AddCustomer />
                },
                {
                    path: '/customer/list/',
                    element: <CustomerList />
                },
                {
                    path: '/customer/kyc/',
                    element: <CustomerKYCDocument />
                }
            ]
        },
        {
            path: 'billing',
            children: [
                {
                    path:'/billing/ratecard/',
                    element: <RateCard />
                },
                {
                    path:'/billing/rateCalculator/',
                    element: <RateCalculator />
                },
                {
                    path:'/billing/remittance/',
                    element:<Remittance />
                }
                
            ]
        },
        {
            path: 'settings',
            children: [
                {
                    path: '/settings/subUser/',
                    element: <SubUser />
                },
                {
                    path: '/settings/pickup/',
                    element: <PickupAddress />
                },
                {
                    path: '/settings/return/',
                    element: <ReturnAddress />
                },
                {
                    path: '/settings/config/',
                    element: <CPanelConfig />
                },
                {
                    path: '/settings/webhook/',
                    element: <WebHook />
                },
                {
                    path: '/settings/couriers/',
                    element: <Couriers />
                },
                
            ]
        },
        {
            path:'/account',
            element: <Account />
        }
    ]
}

export const ErrorRoutes = {
    path: '/',
    children:[
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/pageNotFound',
            element: <Error404 />
        },
        {
            path: '/internalServerError',
            element: <Error500 />
        },
    ]
}

