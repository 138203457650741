export const ActionTypes = {
    //For Config
    API_CONFIG: "API_CONFIG",

    //For Navigations
    MENU_COLLAPSE: "MENU_COLLAPSE",
    ACCESSED_MENU: "ACCESSED_MENU",
    ACCESSED_SUB_MENU: "ACCESSED_SUB_MENU",
    SHOW_ONLY_MENU_ICON: "SHOW_ONLY_MENU_ICON",
    SHOW_FULL_MENU: "SHOW_FULL_MENU",        
    USER_ROLE:"USER_ROLE",

    //For Utility
    THEME_MODE: "THEME_MODE",
    NAV_COLOR: "NAV_COLOR",
    LOADER: "LOADER",
    LINEAR_LOADER: "LINEAR_LOADER",
    FEEDBACK: "FEEDBACK",
    GENERIC_LOADER: "GENERIC_LOADER",


    //For API
    REQUEST_PROGRESS: "REQUEST_PROGRESS",
    REQUEST_TIME: "REQUEST_TIME",
    RESET_API_PROGRESS: "RESET_API_PROGRESS",

    //For Auth
    AUTHORIZATION: "AUTHORIZATION",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    USER_PROFILE: "USER_PROFILE",
    USER_AUTHORIZED: "USER_AUTHORIZED",
    VALIDATE_SESSION: "VALIDATE_SESSION",
    VALIDATE_USERNAME: "VALIDATE_USERNAME",
    RESET_PASSWORD: "RESET_PASSWORD",
    LOGOUT: "LOGOUT",
    RESET_STORE: "RESET_STORE",
    UPDATE_LOGIN_CUSTOMER : "UPDATE_LOGIN_CUSTOMER",
    GET_SUB_USERS: "GET_SUB_USERS",
    ADD_SUB_USER: "ADD_SUB_USER",
    ADD_CUSTOMER_USER: "ADD_CUSTOMER_USER",

    //For Couriers
    GET_COURIERS : "GET_COURIERS",
    GET_COURIERS_PRIORITY : "GET_COURIERS_PRIORITY",
    GET_COURIERS_AND_PRIORITY : "GET_COURIERS_AND_PRIORITY",
    UPDATE_PRIORITY : "UPDATE_PRIORITY",

    //For Customer
    GET_CUSTOMERS: "GET_CUSTOMERS",
    UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
    GET_WEBHOOK:"GET_WEBHOOK",
    UPDATE_WEBHOOK: "UPDATE_WEBHOOK",
    RATE_CARD: "RATE_CARD",

    //For Order
    ORDER_MANIFEST: "ORDER_MANIFEST",
    ORDER_LIST: "ORDER_LIST",

    //For Shipment
    SHIPMENT_MANIFEST: "SHIPMENT_MANIFEST",
    SHIPMENT_LIST: "SHIPMENT_LIST",
    GET_SHIPMENT_QUEUE: "GET_SHIPMENT_QUEUE",
    SHIPMENT_CANCEL: "SHIPMENT_CANCEL",
    TRACKING: "TRACKING",
    //Dashboard
    GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
    GET_PIE_CHART: "GET_PIE_CHART",
    GET_BAR_CHART: "GET_BAR_CHART",
    GET_GEO:"GET_GEO",

    //Masters
    GET_GENERIC_APP_MASTER: "GET_GENERIC_APP_MASTER",
    GET_REPORTS: "GET_REPORTS",
    GET_STATUS_CODE: "GET_STATUS_CODE",
    GENERIC_MASTERS: "GENERIC_MASTERS",

    //Masters
    GET_API_DETAIL : "GET_API_DETAIL",
    GET_PIPNCODES: "GET_PINCODES",
    //Utils
    LOAD_FILE_FROM_SERVER:  "LOAD_FILE_FROM_SERVER",
    //Billing
    RATE_CALCULATION: "RATE_CALCULATION"

};
