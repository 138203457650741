export const Constants1 = {
    API_UAT_URL: "http://translogics.esoftel.in:8080/api/v2",
    API_PROD_URL: "http://api.translogics.in/api/v2",
    MEDIA_URL: "http://translogics.esoftel.in:8080",
    MEDIA_PROD_URL: "http://media.translogics.in",
}

export const Constants = {
    prod:{
        API_URL: "http://api.translogics.in/api/v2",
        MEDIA_URL: "http://api.translogics.in"
    },
    uat:{
        API_URL: "http://uatapi.translogics.in/api/v2",
        MEDIA_URL: "http://uatmedia.translogics.in"
    }
}