import { ActionTypes } from "../Constants";
import { actionInterceptor } from "./actionInterceptor";
import { loader, showLinearLoader } from "./loader";

export const combinedMaster = async(params) => {
    const { dispatch , navigate, getState, payload} = params;
    const url = '/masters/combinedMasters/';
    const method = 'get';
    const newParams = { router: navigate, action: ActionTypes.GET_API_DETAIL, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(newParams);
    dispatch({ type: ActionTypes.GENERIC_MASTERS, payload: response.data.data })
}

export const combinedRole = async(params) => {
    const { dispatch , navigate, getState, payload} = params;
    const url = '/role/combined/';
    const method = 'get';
    const newParams = { router: navigate, action: ActionTypes.GET_API_DETAIL, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(newParams);
    dispatch({ type: ActionTypes.USER_ROLE, payload: response.data.data })
    return response.data.data
}


export const masterIndex = (navigate, payload = {}) => async (dispatch, getState) => {
    const params = { dispatch, getState, navigate, payload }
    combinedMaster(params)
    return combinedRole(params)

}