
import './App.css';
import NavigationScroll from './Module/Navigations/navigationScroll';
import Routes from './Module/Routes';
import DefaultTheme from './Module/Theme';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loader from './Module/Loader/overlayLoader';
import { connect, useDispatch, useSelector } from 'react-redux';
import { closeSnackbar, SnackbarProvider, useSnackbar } from 'notistack';
import { Cancel } from '@mui/icons-material';
import { useEffect } from 'react';
import { setConfig } from './Module/Redux/Actions/config';
import { Constants } from './Module/Api/Constants';

const withRouter = Component => props => {
  const { closeSnackbar } = useSnackbar();
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  return <Component {...props} router={{ location, navigate, params }} />;
}

function App(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(state => state)
  const mainProps = { ...props, ...navigate }

  const onClose = (sid) => {
    closeSnackbar(sid)
  }
  useEffect(()=>{
    var loc = window.location;
    dispatch(setConfig({host: loc.host, source: loc.host === 'cp.translogics.in' ? Constants.prod : Constants.prod}))
  }, [])

  return (
    <SnackbarProvider action={(sid) => (
      <Cancel onClick={() => { closeSnackbar(sid) }}/>
    )}
      maxSnack={10} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={10000}>
      <DefaultTheme {...mainProps}>
        <NavigationScroll {...mainProps}>
          <Loader {...mainProps} />
          <Routes {...mainProps} />
        </NavigationScroll>
      </DefaultTheme>
    </SnackbarProvider>
  );
}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(withRouter(App));
