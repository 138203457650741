import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router";
import Header from "./header";
import CustomBreadcrumbs from "./breadcrumbs";
import { styled, useTheme } from '@mui/material/styles';
import CustomSnackBar from "../Utils/customSnackBar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Main = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
        width: '100%',
    },
}));

const BoxMain = styled(Box)(({ theme }) => ({
    boxShadow: '0 25px 50px rgb(8 21 66 / 6%)',
    transition: 'box-shadow ease .2s',
}))


export default function MainLayout(props) {
    const theme = useTheme();
    const forMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const dispatch = useDispatch();
    const showIcon = useSelector(state => state.nav.showOnlyIconR);
    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} flexGrow={1}>
            <CustomSnackBar {...props} />
            <CssBaseline />
            <BoxMain sx={[{ zIndex: 2, mt: 6, }, !forMobile && { width: showIcon ? '6%' : '15%' }]}>
                <Header />
            </BoxMain>
            <Main sx={[{ pt: 6, zIndex: 1, }, !forMobile && { width: showIcon ? '94%' : '84%', ml: showIcon ? 1 : 5 }]}>
                <Box sx={{p:1, borderRadius: 1, borderWidth: 1, width: '100%' }}>
                    <Box >
                        <CustomBreadcrumbs />
                    </Box>
                    <Box>
                        <Outlet />
                    </Box>
                </Box>
            </Main>
        </Box>
    )
}