import {
    AppBar, Box, Button, Slide, useTheme, TextField, MenuItem,
    Typography, Link, Dialog, DialogTitle, DialogContent, DialogActions, Grid, useMediaQuery, Divider
} from '@mui/material';
import { forwardRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import styled from '@mui/system/styled';
import { getCustomers } from '../Redux/Actions/customer';
import { getCourier, getCourierAndPriority, updatePriority } from '../Redux/Actions/courier';
import { updateLoginCustomer } from '../Redux/Actions/auth';
import { useSnackbar } from 'notistack';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const AccountHeaderInfo = forwardRef(function AccountHeaderInfo(props, ref) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const forMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const account = useSelector(state => state.authentication.userProfile);
    const [couriers, setCouriers] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [disableSelectCustomer, setDisableSelectCustomer] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState("0");
    const [selectedCourier, setSelectedCourier] = useState("");
    const [selectedPrioirty, setSelectedPriority] = useState("");
    const [disableQuickAction, setDisableQuickAction] = useState(false);
    const [quickActionDailogOpen, setQuickActionDailogOpen] = useState(false);
    const handleQuickActionDailog = () => {
        setQuickActionDailogOpen(!quickActionDailogOpen)
    }
    const loadCustomers = async () => {
        if (account && account.department.name !== "Admin") {
            return false;
        }
        const response = await dispatch(getCustomers(navigate))
        setCustomers(response.data)
    }
    const loadCouriers = async () => {
        const response = await dispatch(getCourierAndPriority(navigate))
        setCouriers(response.couriers)
        setPriorities(response.priorities)
    }

    const handleUpdatePriority = async () => {
        let payload = { courier_id: selectedCourier, priority: selectedPrioirty }
        const response = await dispatch(updatePriority(navigate, payload, 'Priority'))
        if (response.status === "Success") {
            enqueueSnackbar("Priority Updated Successfully", { variant: "success" })
        } else {
            enqueueSnackbar(response.message, { variant: "error" })
        }
    }

    const handleOnChange = async (e) => {
        const { name, value } = e.target;
        setSelectedCustomer(value)
        let payload = { cid: value }
        const response = await dispatch(updateLoginCustomer(navigate, payload))
        enqueueSnackbar(response.message, { variant: "success" })
    }
    useEffect(() => {
        if (account) {
            if (!account.customer) {
                loadCustomers()
                setDisableQuickAction(true)
                setDisableSelectCustomer(false)
                setSelectedCustomer("0")
                setCustomers([])
            } else {
                setDisableSelectCustomer(true)
                setSelectedCustomer(account.customer.id)
                setCustomers([account.customer])
                setDisableQuickAction(false)
            }
            loadCouriers()
        }
        
    }, [])
    const renderDailog = (
        <Dialog ref={ref} onClose={handleQuickActionDailog} TransitionComponent={Transition}
            keepMounted open={quickActionDailogOpen} fullWidth>
            <DialogTitle>Quick Actions</DialogTitle>
            <Divider />
            <DialogContent>
                {
                    account
                    &&
                    account.department.name === "Admin"
                    &&
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            sx={{ ...theme.customInput, }}
                            defaultValue={""}
                            fullWidth
                            id={'customers'}
                            label={"Select Customer"}
                            name={"selected_customer"}
                            select
                            SelectProps={{
                                variant: 'standard'
                            }}
                            value={selectedCustomer}
                            onChange={handleOnChange}
                            disabled={disableSelectCustomer}
                        >
                            <MenuItem value={'0'}>Empty</MenuItem>
                            {
                                customers.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>
                }
                <Box sx={{ mt: 2, width: '100%' }}>
                    <Typography variant='h6' sx={{ fontSize: 16 }}>Modify Courier Priority</Typography>
                    <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                        <Grid item xs>
                            <TextField
                                sx={{ ...theme.customInput, }}
                                fullWidth
                                name="courier"
                                label="Select Courier"
                                select
                                defaultValue={""}
                                SelectProps={{
                                    variant: 'standard'
                                }}
                                value={selectedCourier}
                                onChange={(e) => setSelectedCourier(e.target.value)}
                            >
                                {
                                    couriers.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                sx={{ ...theme.customInput, }}
                                fullWidth
                                name="priority"
                                label="Select Priority"
                                select
                                defaultValue={""}
                                SelectProps={{
                                    variant: 'standard'
                                }}
                                value={selectedPrioirty}
                                onChange={(e) => setSelectedPriority(e.target.value)}
                            >
                                {

                                    priorities.map((item, index) => (
                                        <MenuItem key={index} value={item.code}>{item.code}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={forMobile ? 12 : 2}>
                            <Button onClick={handleUpdatePriority} disabled={!!!selectedCourier || !!!selectedPrioirty} fullWidth variant='contained' sx={[forMobile ? {textTransform:'none'} : { textTransform:'none',mt: 3 }]} >Update</Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color='error' variant='contained' onClick={handleQuickActionDailog} sx={{textTransform:'none'}}>Close</Button>
            </DialogActions>
        </Dialog>
    );
    if (account) {
        return (
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center' }}>
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center' }}>
                    {
                        !disableQuickAction
                        &&
                        <Link component="button"
                            variant='button' sx={{ mr: 5, textTransform: 'none' }} onClick={handleQuickActionDailog}>Quick Actions</Link>
                    }
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        {account.customer ? account.customer.name+"("+account.customer.code+")" : account.fullname}
                    </Typography>
                </Box>
                {renderDailog}
            </Box>

        );
    } else {
        return null;
    }

});

export default AccountHeaderInfo;