import { ActionTypes } from "../Constants";
import { actionInterceptor } from "./actionInterceptor";
import { showFeedback } from "./feedback";
import { loader, showLinearLoader } from "./loader";


export const getCourier = (navigate, payload = null) => async (dispatch, getState) => {
    const url = '/partner/courier/'+(payload ? "?cid="+payload : "");
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.GET_COURIERS, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const getCourierPriority = (navigate, payload = null) => async (dispatch, getState) => {
    //dispatch(showLinearLoader(true));
    const url = '/partner/courierPriority/'+(payload ? "?cid="+payload : "");
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.GET_COURIERS_PRIORITY, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const getCourierAndPriority = (navigate, payload = {}) => async (dispatch, getState) => {
    const url = '/partner/courierAndpriority/?type='+payload;
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.GET_COURIERS_AND_PRIORITY, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const updatePriority = (navigate, payload = {}, type, extraParam=null) => async (dispatch, getState) => {
    dispatch(loader({ state: true, title: "Loading..." }))
    const url = '/partner/courierPriority/?type='+type+(extraParam ? "&cid="+extraParam : "");
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.UPDATE_PRIORITY, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}