import { Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography, Icon, Hidden, Avatar, Link, IconButton, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore, Settings } from '@mui/icons-material'
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { menuCollapse, setAccessedMenu, setAccessedSubMenu } from "../Redux/Actions/nav";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import Logo from '../Assets/Static/Images/logo1.jpeg';
import Item from "../UIComponents/Default/paper";

const MainDiv = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        //marginTop: 50
    },
}));

export default function SideBar(props) {
    const theme = useTheme();
    const forMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRole = useSelector(state => state.masters.userRole);
    const subMenu = useSelector(state => state.nav.accessedSubMenu);
    const isMenuCollapse = useSelector(state => state.nav.menuCollapse);
    const showIcon = useSelector(state => state.nav.showOnlyIconR);

    const handleMenuClick = async (item) => {
        if (!forMobile) {
            props.handleDrawer()
        }
        if (item.submenu.length === 0) {
            navigate(item.url)

            dispatch(menuCollapse(false))
            dispatch(setAccessedMenu(item))
            dispatch(setAccessedSubMenu(null))
        } else {
            if (isMenuCollapse === item.id) {
                dispatch(menuCollapse(0))
            } else {
                dispatch(menuCollapse(item.id))
            }

        }
    };
    // const handleSubMenuClick = async (menu, subMenu) => {
    //     console.log('clicked---')
    //     navigate(subMenu.url)
    //     props.handleDrawer()
    //     dispatch(setAccessedMenu(menu))
    //     dispatch(setAccessedSubMenu(subMenu))
    // };
    return (
        <MainDiv component="div" sx={{ height: '100vh', }}>
            <Item>
            <List
                sx={{ height: forMobile ? '100vh' : '92vh', overflowY:'auto', overflowX:'hidden',
                // '&::-webkit-scrollbar': {
                //     width: '0.4em'
                //   },
                //   '&::-webkit-scrollbar-track': {
                //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                //     webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                //   },
                //   '&::-webkit-scrollbar-thumb': {
                //     backgroundColor: 'rgba(0,0,0,.1)',
                //     borderRadius:2,
                //     //outline: '1px solid slategrey'
                //   },
                //   "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                //     backgroundColor: "#959595",
                //   },
                //   "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                //     backgroundColor: "#959595",
                //   },
                //   "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                //     backgroundColor: "#959595",
                //   },
                //   "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                //     backgroundColor: "#2b2b2b",
                //   },
                  
             }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <>
                        <Hidden lgUp>
                            <img src={Logo} style={{ margin: 5, height: 30, width: 160 }} />
                        </Hidden>

                    </>
                }
            >
                {
                    userRole && userRole.sideBar.map((item, index) => {
                        return (
                            <Box key={index} sx={{}}>
                                <ListItemButton onClick={() => handleMenuClick(item)} >
                                    <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
                                        <Avatar variant="rounded" sx={{ bgcolor: "#222f3e" }}>
                                            <Icon fontSize="small" >{item.icon}</Icon>
                                        </Avatar>
                                    </ListItemIcon>
                                    {
                                        !showIcon &&
                                        <ListItemText sx={{ opacity: !showIcon ? 1 : 0, mx: 1.5 }} primary={
                                            <Typography component="a" variant={'body1'} color="inherit" sx={{ my: 'auto', fontSize: 14, fontWeight: 700 }}>
                                                {item.title}
                                            </Typography>
                                        } />
                                    }
                                    {
                                        !showIcon && item.submenu.length > 0 ?
                                            isMenuCollapse === item.id ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" /> : null
                                    }
                                </ListItemButton>
                                {
                                    !showIcon && item.submenu &&
                                    <Collapse in={isMenuCollapse === item.id ? true : false} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding sx={{ mx: 2, display: 'flex', flexDirection: 'column', color: 'inherit' }}>
                                            {
                                                item.submenu.map((sitem, sindex) => {
                                                    return (

                                                        <Link className={subMenu && subMenu.id === sitem.id ? "active" : "inactive"}
                                                            key={sindex}
                                                            underline={"none"}
                                                            href={sitem.url}
                                                            //onClick={() => handleSubMenuClick(item, sitem)}
                                                            sx={[{
                                                                cursor: 'pointer',
                                                                p: 1, mt: 1, ml: 5, borderRadius: 1, color: "#222f3e",
                                                                display: 'flex', flexDirection: 'row',
                                                                '&:hover': {
                                                                    bgcolor: "#0a70d621",
                                                                    color: "#1976d2",
                                                                    '& .MuiListItemIcon-root': {
                                                                        color: "#1976d2",
                                                                    }
                                                                },
                                                                '&.active': {
                                                                    bgcolor: "#0a70d621",
                                                                    color: "#1976d2",
                                                                    '& .MuiListItemIcon-root': {
                                                                        color: "#1976d2",
                                                                    }
                                                                }
                                                            }]}
                                                        >
                                                            <ListItemIcon color="inherit" sx={{ my: 'auto', minWidth: 20 }}>
                                                                <FiberManualRecordIcon className="linkIcon" sx={{
                                                                    width: 8, height: 8,
                                                                }} />
                                                            </ListItemIcon>
                                                            <Typography variant={'body1'} sx={{ my: 'auto', fontSize: 13, fontWeight: 500 }}>
                                                                {sitem.title}
                                                            </Typography>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Collapse>
                                }
                            </Box>
                        )
                    })
                }
            </List>
            </Item>

        </MainDiv>
    )
}