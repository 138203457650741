import { Paper, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    /* From https://css.glass */
    background: 'rgba(255, 255, 255, 0.59)',
    //borderRadius: 16px;
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(6.1px)',
    //-webkit - backdrop - filter: blur(6.1px);
    border: '1px solid rgba(255, 255, 255, 0.7)',
}));

export default Item;