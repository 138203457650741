import axios from "axios";
import { Constants } from "../../Api/Constants";
import { ActionTypes } from "../Constants";
import { actionInterceptor } from "./actionInterceptor";
import { getAccountDetail } from "./auth";
import { showFeedback } from "./feedback";
import { loader, showLinearLoader } from "./loader";
import { masterIndex } from "./master";


export const getCustomers = (navigate, payload = {}) => async (dispatch, getState) => {
    //dispatch(showLinearLoader(true));
    const url = '/customer/getCustomers/';
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.GET_CUSTOMERS, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const updateCustomers = (navigate, payload = {}, type) => async (dispatch, getState) => {
    dispatch(loader({ state: true, title: "Loading..." }))
    const url = '/customer/addCustomer/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.UPDATE_CUSTOMER, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    dispatch(masterIndex(navigate))
    dispatch(getAccountDetail(navigate))
    return response.data;
}

export const uploadProfileImage = (navigate, payload = {}, type) => async (dispatch, getState) =>{
    dispatch(loader({ state: true, title: "Uploading..." }))
    const url = '/customer/profileImage/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.UPDATE_CUSTOMER, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    dispatch(getAccountDetail(navigate))
    return response.data;
}

export const uploadKYCDocument = (navigate, payload = {}, type) => async (dispatch, getState) =>{
    dispatch(loader({ state: true, title: "Uploading..." }))
    const url = '/customer/kyc/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.UPDATE_CUSTOMER, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const getKYCDocument = (navigate, payload = null) => async (dispatch, getState) =>{
    dispatch(loader({ state: true, title: "Loading..." }))
    const url = '/customer/kyc/'+(payload ? '?cid='+payload : '');
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.UPDATE_CUSTOMER, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const getPushWebhook = (navigate, payload = {}) => async (dispatch, getState) => {
    dispatch(loader({ state: true, title: "Loading..." }))
    const url = '/customer/pushWebhook/';
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.GET_WEBHOOK, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const addPushWebhook = (navigate, payload = {}, type) => async (dispatch, getState) => {
    dispatch(loader({ state: true, title: "Adding..." }))
    const url = '/customer/pushWebhook/?type='+type;
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.UPDATE_WEBHOOK, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}

export const getRateCards = (navigate, payload = {}) => async (dispatch, getState) => {
    dispatch(loader({ state: true, title: "Loading..." }))
    const url = '/customer/rateCard/';
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.RATE_CARD, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    return response.data;
}