import { createBrowserHistory } from 'history';
import { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { createBrowserRouter } from 'react-router-dom';

// project imports
import Loadable from "../Navigations/loadable";
//import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const Login = Loadable(lazy(() => import('../UIComponents/Authentications/login')));
const ForgetPassword = Loadable(lazy(() => import('../UIComponents/Authentications/forgetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    children: [
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/forgetPassword',
            element: <ForgetPassword />,
        }
    ]

}




export default AuthenticationRoutes;
