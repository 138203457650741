import { ActionTypes } from "../Constants";
import { actionInterceptor } from "./actionInterceptor";
import { loader, showLinearLoader } from "./loader";
import { masterIndex } from "./master";
import { setAccessedMenu } from "./nav";

export const getToken = (navigate, payload = {}) => async (dispatch, getState) => {
    dispatch({ type : ActionTypes.LOADER, payload: {state:true, title:"Logging..."}})
    const url = '/auth/getToken/';
    const method = 'post';
    const params = {router: navigate, action: ActionTypes.AUTHORIZATION, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    if (response.data.status && response.data.status === "Success"){
        dispatch({ type: ActionTypes.VALIDATE_SESSION, payload: true });
        dispatch({ type: ActionTypes.AUTHORIZATION, payload: response.data });
        dispatch({ type: ActionTypes.LOGOUT, payload: false });
        await dispatch(getAccountDetail(navigate));
        await dispatch(masterIndex(navigate));
        await dispatch(setAccessedMenu({id:1, title:"Dashboard", url:'/dashboard'}));
        await navigate('/dashboard');
    }else if (response.data.status && response.data.status === "Failed"){
        dispatch(loginResponse(response.data))
        dispatch({ type: ActionTypes.VALIDATE_SESSION, payload: false });
        dispatch({ type: ActionTypes.AUTHORIZATION, payload: null });
    }
    return response.data;
};

export const getAccountDetail = (navigate, payload = {}) => async (dispatch, getState) => {
    //dispatch({ type : ActionTypes.GENERIC_LOADER, payload: {state:true, linear:false, overlay:true, component: ActionTypes.USER_PROFILE, title:"Fetching..."}})
    const url = '/auth/account/';
    const method = 'get';
    const params = {router: navigate, action: ActionTypes.USER_PROFILE, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    dispatch({ type : ActionTypes.USER_PROFILE, payload: response.data.data})
    return response.data
}

export const validateSession = (navigate, payload = {}) => async (dispatch, getState) => {
    //dispatch({ type : ActionTypes.GENERIC_LOADER, payload: {state:true, linear:false, overlay:true, component: ActionTypes.VALIDATE_SESSION, title:"Validating..."}})
    const url = '/auth/validateSession/';
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.VALIDATE_SESSION, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    dispatch(masterIndex(navigate))
    dispatch(getAccountDetail(navigate))
    return response.data
}

export const validateUsername = (navigate, payload = {}) => async (dispatch, getState) => {    
    dispatch(loader({ state: true, title: "Validating..." }))
    const url = '/auth/validateUsername/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.VALIDATE_USERNAME, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    dispatch({ type: "LOADER", payload: { state: false, message: "" } })
    return response.data
}

export const resetPassword = (navigate, payload = {}) => async (dispatch, getState) => {    
    dispatch(loader({ state: true, title: "Validating..." }))
    const url = '/auth/resetPassword/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.RESET_PASSWORD, dispatch, getState, url, method, payload};
    const response = await actionInterceptor(params);
    dispatch({ type: "LOADER", payload: { state: false, message: "" } })
    return response.data
}

export const logout = (navigate, payload = {}) => async (dispatch, getState) => {
    dispatch({ type : ActionTypes.LOADER, payload: {state:true, title:"Logging Out..."}})
    const url = '/auth/logout/';
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.LOGOUT, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    dispatch({ type: ActionTypes.LOGOUT, payload: true });
    dispatch({ type: ActionTypes.RESET_STORE, payload: true });
    navigate('/login')
    return response.data
}

export const getSubUsers = (navigate, payload = {}) => async (dispatch, getState) => {
    dispatch(showLinearLoader(true));
    const url = '/auth/subuser/';
    const method = 'get';
    const params = { router: navigate, action: ActionTypes.GET_SUB_USERS, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    return response.data
}

export const addSubUser = (navigate, payload = {}, type) => async (dispatch, getState) => {
    dispatch({ type : ActionTypes.LOADER, payload: {state:true, title: type==="update" ? "Updating..." : "Adding..."}})
    const url = '/auth/subuser/?type='+type;
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.ADD_SUB_USER, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    return response.data
}

export const addCustomerUser = (navigate, payload = {}, type) => async (dispatch, getState) => {
    dispatch({ type : ActionTypes.LOADER, payload: {state:true, title: "Adding..."}})
    const url = '/auth/cur/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.ADD_CUSTOMER_USER, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    return response.data
}

export const updateLoginCustomer = (navigate, payload = {}) => async (dispatch, getState) => {
    dispatch(loader({ state: true, title: "Loading..." }))
    const url = '/auth/updateCustomer/';
    const method = 'post';
    const params = { router: navigate, action: ActionTypes.LOGOUT, dispatch, getState, url, method, payload };
    const response = await actionInterceptor(params);
    dispatch(getAccountDetail(navigate))
    return response.data
}

export const loginResponse = (payload) =>{
    return {
        type: ActionTypes.LOGIN_FAILURE,
        payload: payload
    }
}

export const setAuthorized = (payload) => {
    return {
        type: ActionTypes.USER_AUTHORIZED,
        payload: payload
    }
}

export const setProfileData = (payload) => {
    return {
        type: ActionTypes.USER_PROFILE,
        payload: payload
    }
}