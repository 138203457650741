import { ActionTypes } from "../Constants";

const initialState = {
    progress: 0,
    requestTime: null,
};

export const api = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.REQUEST_PROGRESS:
            return { ...state, progress: payload };
        case ActionTypes.REQUEST_TIME:
            return { ...state, requestTime: payload };
            case ActionTypes.RESET_API_PROGRESS:
                return initialState;
        default:
            return state;
    };
};