import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';



export default function DefaultTheme({ children }) {
  const muiDefaultTheme = useTheme();
  const thememode = "light"//useSelector(state => state.theme.mode);
  const navColor = "#222f3e"//useSelector(state => state.theme.nav);
  const darkColor = "#222f3e";
  const lightColor = "#fff";

  const customTheme = createTheme({
    palette: {
      mode: thememode,
      background: {
        paper: thememode === "dark" ? darkColor : lightColor,
        default: thememode === "dark" ? "#35424fd6" : "#edf2f9",
      },
      text: {
        primary: thememode === "dark" ? lightColor : darkColor,
        secondary: thememode === "dark" ? lightColor : darkColor,
        disabled: thememode === "dark" ? lightColor : darkColor,
        icon: thememode === "dark" ? lightColor : darkColor
      },
      header: {
        bg: navColor
      },
      action: {
        active: thememode === "dark" ? lightColor : darkColor,
      },

      /*
      header: {
        bg: navColor
      },
      icon:{
        inactive:"#637381",
        active:"red",
      },
      navLink: {
        bg: navColor
      },
      action: {
        hover: "rgba(34,47,62,0.30)"//"#222f3e"//"rgba(0,0,0,0.9)",

      },
      background: {
        paper: '#fff',//thememode == "light" ? "#fff" : "rgb(49,53,63)",
        default: '#eef2f6',//thememode == "light" ? "#fff" : "rgb(49,53,63)",
      }
       */
    },
    customInput: {
      marginTop:2,
      '& > label': {
        padding:1,
        fontSize: 16,
        //bottom: 10,
        lineHeight: '.9em',
        '&[data-shrink="false"]': {
          top: -5

        },
        '&[data-shrink="true"]': {
          top: -15,
          bottom:8,
          fontSize: 16,
        }
      },
      '& legend': {
        display: 'none'
      },
      '& > legend > span': {
        fontSize: 20,
      },
      '& fieldset': {
        top: 2,

      },
      '& > div > input': {
        paddingTop: 1.6,
        paddingBottom: .8,
        paddingLeft: 1,
        paddingRight: 1
      },
      '& > div >[id="stdSelect"]': {
        paddingTop: 1.6,
        paddingBottom: .8,
        paddingLeft: 1,
        paddingRight: 1
      }

    },
    customSelect:{
      '& > div':{        
        minHeight:0,
        paddingTop: 1.6,
        paddingBottom: .8,
        paddingLeft: 1,
        paddingRight: 1
      }
    },
  })
  const theme = { ...muiDefaultTheme, ...customTheme }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}