import { Constants } from "../../Api/Constants";
import { ActionTypes } from "../Constants";

const initialState = {
    host: window.location.host,
    source: window.location.host === 'cp.translogics.in' ? Constants.prod : Constants.uat
};

export const appConfig = (state = initialState, { type, payload }) => {
    
    switch (type) {
        case ActionTypes.API_CONFIG:
            return { ...state, ...payload };
        default:
            return state;
    };
};