import { ActionTypes } from "../Constants";

const initialState = {
    manifestResponse: null,
    orderList: []
};

export const order = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.ORDER_MANIFEST:
            return { ...state, manifestResponse: payload };
        case ActionTypes.ORDER_LIST:
            return { ...state, orderList: payload };
        default:
            return state;
    };
};