import { Breadcrumbs, Link, Stack, Box, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setAccessedSubMenu } from "../Redux/Actions/nav";

const color = '#0080001f'

export default function CustomBreadcrumbs(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const menu = useSelector(state => state.nav.accessedMenu);
    const subMenu = useSelector(state => state.nav.accessedSubMenu);
    return (
        <Stack spacing={2} mb={1}  style={{ borderRadius: 4,}}>
            {
                subMenu ?
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="medium" />}
                        aria-label="breadcrumb"
                    >
                        <Link underline="none" sx={{ "&:hover": { textDecoration: "underline #0073bb" } }}>{menu.title}</Link>
                        <Link underline="none" href={subMenu.url} sx={{ "&:hover": { textDecoration: "underline #0073bb" } }}>{subMenu.title}</Link>
                    </Breadcrumbs>
                    :
                    menu ? <Link underline="none" href={menu.url} sx={{ "&:hover": { textDecoration: "underline #0073bb" } }}>{menu.title}</Link> : null
            }
            {
                subMenu ?
                    subMenu.description
                        ?
                        <Box component="div" bgcolor={"#222f3e"} sx={{ borderRadius: .9, p: 1 }}>
                            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: 400, color:'#fff' }}>{subMenu.description}</Typography>
                        </Box>
                        :
                        menu
                            ?
                            menu.description
                                ?
                                <Box component="div" bgcolor={"#222f3e"} sx={{ borderRadius: .9, p: 1 }}>
                                    <Typography variant="body2" sx={{ fontSize: 12, fontWeight: 400, color:'#fff' }}>{menu.description}</Typography>
                                </Box>
                                :
                                null
                            : null : null
            }
        </Stack >
    )
}