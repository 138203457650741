import { ActionTypes } from "../Constants";

const initialState = {
    genericMasters:null,
    statusCodes:[],
    userRole:null
};

export const masters = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.GENERIC_MASTERS:
            return { ...state, genericMasters: payload };
        case ActionTypes.GET_STATUS_CODE:
            return { ...state, statusCodes: payload };
        case ActionTypes.USER_ROLE:
            return { ...state, userRole: payload };
        default:
            return state;
    };
};