import { useRef, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import { Avatar, Button, Divider, Drawer, Hidden, Link, useMediaQuery } from '@mui/material';
import SideBar from './sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { menuCollapse, setAccessedMenu, setAccessedSubMenu, showFullMenu, showOnlyIconR } from '../Redux/Actions/nav';
import { logout } from '../Redux/Actions/auth';
import AccountHeaderInfo from './accountHeaderInfo';
import { ChevronLeft, ChevronRight, Directions } from '@mui/icons-material';
import { Constants } from '../Api/Constants';
import Logo from '../Assets/Static/Images/logo1.jpeg';
import { width } from '@mui/system';
import { useTheme } from '@emotion/react';


const drawerWidth = '15%';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));

export default function Header(props) {
    const theme = useTheme();
    const forMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showIcon = useSelector(state => state.nav.showOnlyIconR);
    const account = useSelector(state => state.authentication.userProfile);
    const config = useSelector(state => state.appConfig.source);
    const { window } = props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showIconOnly, setShowIconOnly] = useState(true);

    const handleShowIconOnlyBtn = () => {
        if (forMobile) {
            setShowIconOnly(false)
            dispatch(showOnlyIconR(false))
        } else {
            setShowIconOnly(!showIconOnly)
            dispatch(showOnlyIconR(!showIcon))
        }
    }

    const handleShowFullMenuBtn = () => {
        setShowIconOnly(false)
        dispatch(showOnlyIconR(false))
    }

    const handleDrawerToggle = () => {
        setDrawerOpen((prevState) => !prevState);
        handleShowIconOnlyBtn()
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleAccount = () => {
        handleMenuClose()
        dispatch(menuCollapse(false))
        dispatch(setAccessedMenu({ id: 1, title: "Account", url: '/account' }))
        dispatch(setAccessedSubMenu(null))
        navigate('/account')
    }

    const handleLogout = () => {
        handleMenuClose();
        dispatch(logout(navigate));
        //navigate('/login');
    };


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {
                account && account.customer !== '' &&
                <MenuItem onClick={() => handleAccount()}>My account</MenuItem>
            }
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = () => (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuOpen}

        >

            {/* <MenuItem onClick={handleQuickActions}>
                <AccountHeaderInfo {...props} ref={accountRef} />
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={0} color="error">
                        <Directions />
                    </Badge>
                </IconButton>
                <p>Quick Actions</p>
            </MenuItem> */}
            {/* <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={0} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>

        </Menu>
    );
    const drawer = () => {
        return (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 50 }}>
                <SideBar {...props} handleDrawer={handleShowFullMenuBtn} showIconOnly={showIconOnly} />
            </Box>
        )
    };


    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position='fixed' component="nav" sx={{ bgcolor: "header.bg", paddingTop: "env(safe-area-inset-top)" }}>
                <Toolbar variant='dense'>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, }}
                    >
                        <MenuIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <Hidden lgDown>
                        <img src={Logo} style={{ height: 40, width: 160, borderRadius: 1 }} />
                    </Hidden>
                    {/* <Search >
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                    <Box sx={{ flexGrow: 1 }}>

                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <AccountHeaderInfo {...props} />
                        {/* <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={0} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            {
                                account && account.customer.logo ?
                                    <Avatar imgProps={{
                                        style: { width: '100%', height: 'auto', objectFit: 'cover' }
                                    }}
                                        sx={{ width: 30, height: 30, backgroundColor: '#fff' }} src={account.customer && account.customer.logo ? config.MEDIA_URL + account.customer.logo : null} />
                                    :
                                    <AccountCircle />
                            }
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <AccountHeaderInfo {...props} />
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <Box component="nav" id="custom-nav">
                <Hidden lgUp>
                    <Drawer
                        container={container}
                        open={drawerOpen}
                        onClose={handleDrawerToggle}
                        anchor="left"
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '60%' },
                            height: '100vh',
                        }}
                    >
                        {drawer()}
                    </Drawer>
                </Hidden>

                <Hidden lgDown >
                    <Box flexGrow={1} component="div" bgcolor={"#fff"} sx={{ height: '100vh', width: showIcon ? '6%' : drawerWidth, position: 'fixed' }}
                    >
                        {/* <Hidden lgDown>
                            <Box component="div" sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                mr: 1.5
                            }}>
                                <IconButton
                                    onClick={() => handleShowIconOnlyBtn()}
                                    sx={{
                                        borderRadius: 1,
                                        '&:hover': {
                                            backgroundColor: '#0a70d621',
                                        },
                                    }}>
                                    {
                                        showIcon ? <ChevronRight fontSize="medium" /> : <ChevronLeft fontSize="medium" />
                                    }
                                </IconButton>
                            </Box>
                        </Hidden>
                        <Divider /> */}
                        {drawer()}
                    </Box>
                </Hidden>
            </Box>
        </Box>
    );
}